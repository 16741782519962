.main_section {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  gap: 50px;
}

.totalsum_div {
  padding: 30px;
  border: 3px solid #006100;
  border-radius: 20px;
  font-size: 28px;
  font-weight: 800;
  color: #791f1f;
}

.table_div {
  width: 90%;
}

.section_heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #663B20;

}

.fetch_btn {
  user-select: none;
  position: relative;
  cursor: pointer;
  width: 100px;
  height: 40px;
  background-color: darkgoldenrod;
  border: none;
  border-radius: 10px;

  color: #FFFFFF;
  font-weight: 800;
  font-size: 20px;
}

.home_link {
  text-decoration: underline;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 34px */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #663B20;
}

.cart_item_table_div {
  width: 100%;
}

.cart_item_heading_div {
  padding: 10px 0 10px 0;
  background: #791f1f;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.cart_item_div {
  padding: 10px 0 10px 0;
  background: #F3F3F3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.new_cart_item_background {
  background: #c47171 !important;
}

.cursor_pointer {
  cursor: pointer;
}

.cart_last_item_div {
  padding: 10px 0 10px 0;
  background: #F3F3F3;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.cart_item_small_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-transform: capitalize;

  color: #FFFFFF;
}

.cart_item_big_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.5%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-transform: capitalize;

  color: #FFFFFF;
}

.cart_item_small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #243546;
}

.cart_item_big {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.5%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #243546;
}


.quantity_div {
  padding: 10px 50px 10px 50px;
  border-radius: 10px;
  background: #DDE0E0;
}

.product_images {
  cursor: pointer;
  height: 70px;
  max-width: 90%;
  border-radius: 10px;
}

.price_calculator {
  width: 45%;
}

.nav_btn_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

