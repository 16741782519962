.orders_section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  padding: 0 7vw 50px 7vw;
  box-sizing: border-box;
}

.section_heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  /* identical to box height, or 34px */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #663B20;

}


.cart_item_table_div{
  width: 100%;
}


.cart_item_heading_div {
  padding: 10px 0 10px 0;
  background: #791f1f;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center  !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.delivered_btn{
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  background: #1dbd1d;
  padding: 10px 20px;
  border-radius: 20px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 800;
}

.cart_item_div {
  padding: 10px 0 10px 0;
  background: #F3F3F3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center  !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.cart_item_div_mobile {
  padding: 10px 0 10px 0;
  background: #F3F3F3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center  !important;
  width: 100% !important;
  flex-wrap: wrap;
  gap: 10px;
}

.new_cart_item_background{
  background: #c47171 !important;
}

.cursor_pointer{
  cursor: pointer;
}

.cart_last_item_div {
  padding: 10px 0 10px 0;
  background: #F3F3F3;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center  !important;
  width: 100% !important;
  flex-wrap: nowrap;
  gap: 0;
}

.cart_item_small_heading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-transform: capitalize;

  color: #FFFFFF;
}

.cart_item_big_heading{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.5%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-transform: capitalize;

  color: #FFFFFF;
}


.cart_item_small{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #243546;
}

.cart_item_big{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.5%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #243546;
}




.quantity_div{
  padding: 10px 50px 10px 50px ;
  border-radius: 10px;
  background: #DDE0E0;
}

.product_images{
  cursor: pointer;
  height: 70px;
  max-width: 90%;
  border-radius: 10px;
}

.price_calculator{
  width: 45%;
}

.nav_btn_div{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.sort_btn{
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 34px */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #000000;
  };
}


@media screen and (max-width: 800px) {
  .cart_item_table_div{
    width: 100%;
      display: flex;
      flex-direction: column;
      gap: 50px;
  }
  .cart_item_div_mobile{
    border-radius: 20px;
    max-width: 290px;
  }
  .cart_item_small{
    width: fit-content;
  }

  .cart_item_big{
    width: fit-content;
  }
  .delivered_btn{
    margin-top: 20px;
  }
}