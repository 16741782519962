.dropdown{
  width: 100% !important;
  border-top: 1px solid rgba(24, 18, 2, 0.08);
  padding:  15px 0 0 0;
  img {
    width: 20px;
  }

}
.dropdown__toggle{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  label{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-transform: capitalize;

    color: #181202;
 }
}

.dropdown__option__div{
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dropdown__option{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: calc(30px + 6vw);
  border-bottom: 1px solid rgba(24, 18, 2, 0.08);
}
.checkbox  {

  width: 20px;
  height: 20px;
  box-sizing: border-box;
  accent-color: #092A64;
  padding-right: 0;
  margin: 0;
}
.checkbox:checked ~ .checkmark {
  background-color: #092A64;
}