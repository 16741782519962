.navbar{
  z-index: 2;
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #a2c2dc;
}

.navbar_section{
  box-sizing: border-box;
  padding: 0 7vw 0 7vw !important;
  margin: calc(10px + 3vh) 0 calc(10px + 3vh) 0 ;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.second_btn_div{
  display:flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

.first_btn_div{
  display:flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.catalog_btn{
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #FFFFFF !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;

  color: #FFFFFF;
  border-radius: 10px;
  width: 12vw;
  height: 5vh;
  min-width: 180px;
  min-height: 40px;
  padding: 30px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1vw;
  justify-content: center;
  align-items: center;
  background-color: #791f1f;
}
.home_btn{
  background: none;
  border: none;
  cursor: pointer;
}
.second_section_login_btn{
  cursor: pointer;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  background: none;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-left: 5px;


  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  min-height: 50px;
  min-width: 110px;

  width: 7vw;
  height: 5vh;
  margin-bottom: 10px;
}

.dropdown_menu{
  border: 1px solid #757575;
  position: absolute;
  padding: 10px calc(10px + 0.5vw)  10px calc(10px + 0.5vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #a2c2dc;
  border-radius: 10px;
  gap: 10px;
  button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border: none;
    background: none;
    cursor: pointer;
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height */

      letter-spacing: 0.01em;
      text-transform: capitalize;

      color: #663B20;
    }
  }
  
}

.styles.my_profile_div{
  display: flex;
  justify-content: center;
  align-items: center;
}