.tagInput{
  width: 100%;
  height: fit-content;
}
.input{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: 18px;
  color: #000000;
}

.tag{
  box-sizing: border-box;
  margin: 10px 10px 0 0;
  padding: 8px;
  width: fit-content;
  height: 3vw;
  min-width: 60px;
  min-height: 30px;
  font-weight: 400;
  font-size: 15.36px;
  line-height: 23px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
  background: #EDEDED;
  border-radius: 12px;
  gap: 10px;
  button{
    cursor:pointer
  }
}

.tagDiv{
  width: 200%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.btn{
  background: none;
  border: none;
  img{
    width: 15px;
    height: 15px;
  }
}