.mainDiv{
  z-index: 1 !important;
  padding: 2vh 10vw 2vh 10vw;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #060606;
}

.home_link{
  text-decoration: underline;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 34px */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #663B20;
}
.flexDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.productPreviewHeading{
  z-index: 1 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  min-height: 90px;
  border-bottom: 2px solid #005AFA;
};

.productPreviewDiv{
  cursor: pointer;
  z-index: 1 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
  min-height: 110px;
  box-sizing: border-box;
  padding: 20px 0 20px 0;
  border-bottom: 2px solid #D4D4D4;
};

.activeProductDiv{
  background: #F2F2F9;
}

.productImg {
  z-index: 1 !important;
  height: 87px;
  width:87px;
  border-radius: 50%;
};

.productColumn{
  z-index: 1 !important;
  width: 15vw;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
};

.firstProductColumn{
  z-index: 1 !important;
  width: 8vw;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
};

.firstHeadingColumn{
  z-index: 1 !important;
  width: 8vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.lastProductColumn{
  z-index: 1 !important;
  width: 13vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
};

.activeProduct{
  z-index: 1 !important;
  padding: 6px 10px 6px 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;

  background: rgba(8, 169, 73, 0.68);
  border-radius: 12px;
  border: none;
};

.inactiveProduct{
  z-index: 1 !important;
  padding: 6px 10px 6px 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
  border: none;
  background: rgba(250, 0, 0, 0.68);
  border-radius: 12px;
};
.selectAllBtn{
  cursor: pointer;
  z-index: 1 !important;
  padding: 6px 15px 6px 15px;
  border: 1px solid #005AFA;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  background: none;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #005AFA;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;


}

.changeStatusBtn{
  cursor: pointer;
  z-index: 1 !important;
  padding: 6px 10px 6px 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
  border: none;
  background:  #005AFA;
  border-radius: 12px;
};
.removeBtn{

  cursor: pointer;
  z-index: 1 !important;
  padding: 6px 10px 6px 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
  border: none;
  background: rgba(250, 0, 0, 0.68);
  border-radius: 12px;
};

.editBtn{
  cursor: pointer;
  padding: 6px 10px 6px 10px;
  background: #092A64;
  border-radius: 12px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.toolBar{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap:10px
}
