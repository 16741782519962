.order_details_section {
  box-sizing: border-box;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.product_section {
  width: fit-content;
}

.product_img {
  width: 100px;
}

.product_price {
  width: 100%;
  text-align: end;

  font-size: 22px;
  font-weight: bold;
  font-style: italic;
}

.user_info_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.user_info_section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    font-weight: 500;
    box-sizing: border-box;
    width: 9%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
}

.heading_div {
  div {
    font-weight: bold !important;
    color: #791f1f !important;
  }

  border-bottom: 2px solid #791f1f !important;
}

.user_info_large_div {
  width: 15% !important;
}

.section_heading {
  h2 {
    color: #791f1f !important;
  }
}

.product_data {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  box-sizing: border-box;
  padding: 20px;


  border-bottom: 2px solid #791f1f !important;

  span {
    border-right: 2px solid #791f1f !important;
    box-sizing: border-box;
    padding: 0 20px;
    font-weight: 500;
  }
}

.shipping_address {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 500;
}

.heading_span {
  display: inline-block;
  font-size: 16px;
  font-weight: 700 !important;
  color: #791f1f !important;
}

.link_menu {

  display: flex;
  flex-direction: row;
  gap: 10px;

  a {
    color: #791f1f;
    text-decoration: none;
    border-bottom: 1px solid #791f1f;
    font-weight: 800;
  }
}

@media screen and (max-width: 800px) {
  .order_details_section {
    box-sizing: border-box;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .product_section {
    width: fit-content;
  }
  .product_img {
    width: 50px;
  }

  .product_price {
    width: 100%;
    text-align: end;

    font-size: 22px;
    font-weight: bold;
    font-style: italic;
  }
  .user_info_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .user_info_section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      font-weight: 500;
      box-sizing: border-box;
      width: 9%;
      display: flex;
      justify-content: center;
      font-size: 8px;
      padding: 20px 0;

    }
  }

  .heading_div {
    div {
      font-weight: bold !important;
      color: #791f1f !important;
      font-size: 12px;
    }

    border-bottom: 2px solid #791f1f !important;
  }
  .user_info_large_div {
    width: 15% !important;
  }

  .section_heading {
    h2 {
      color: #791f1f !important;
    }
  }

  .product_data {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
    padding: 5px;


    border-bottom: 2px solid #791f1f !important;

    span {
      border-right: 2px solid #791f1f !important;
      box-sizing: border-box;
      padding: 0 0px;
      font-weight: 500;
      font-size: 10px;
    }
  }

  .shipping_address {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 500;
  }

  .heading_span {
    display: inline-block;
    font-size: 10px;
    font-weight: 700 !important;
    color: #791f1f !important;
    * {
      font-size: 10px;
    }
  }

  .link_menu {

    display: flex;
    flex-direction: row;
    gap: 10px;

    a {
      color: #791f1f;
      text-decoration: none;
      border-bottom: 1px solid #791f1f;
      font-weight: 800;
    }
  }
}