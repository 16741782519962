.mainSection{
  padding: 2vh 2vw 2vh 2vw;
}

.centeredFlexDiv{
  padding: 2vh 4vw 2vh 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product_title_div{
  display: flex;
  width: 100%;
}

.product_description_div{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label{
  user-select: none;
  margin: 0 30px 0 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-transform: capitalize;

  color: #181202;
}
.horizontalDiv{
  width: 100%;
  display: inline-block;
}
.inputField{
  display: inline-block;
  width: 300px;

  input{
    color: #000000 !important;
    height: 6vh;
  }
}

.slider_visibility_checkbox{
  cursor: pointer;
  
  input{
    cursor: pointer;
  }
  label{
    cursor: pointer;
  }
}

.inlineDiv{
  display: inline-block;
  width: 30%;
  box-sizing: border-box;
  padding: 0;
}
.optionalField{
  color: #000000 !important;
  font-size: 18px;
  height: 6vh;
  width: 100%;
}

.flexDiv{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.textArea{
  box-sizing: border-box;
  padding:18px 18px 18px 18px;
  z-index: 1 !important;
  width: 50% !important;
  max-width: 100% !important;
  min-height: 135px;
  background-color: #FFFFFF;
  border: 1px solid #D1D2D5;
  border-radius: 12px;
  font-size: 18px;
  color: #000000 !important;
}

.fileInput::-webkit-file-upload-button,
.fileInput::file-selector-button {
  background: rgba(217, 217, 217, 0.3);
  height: 4vh;
  width: 8vw;
  min-height: 30px;
  min-width: 80px;
  border: 1px solid #929292;
  border-radius: 10px;
  cursor: pointer;
}

.fileInput::after {
  display: inline-block;
  background-color: #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.imgPreviewDiv{
  height: 10vw;
  width: 10vw;
  background: #D6D6D6;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.imgPreview{
  height: 100%;
  width: 100%;
}

.removeImgButton{
  cursor: pointer;
  display: inline-block;
  position: absolute;
  margin-right: 10px;
  background: none;
  border: none;
  width: fit-content;
  box-sizing: border-box;
  padding: 5px 0 0 0;
  img{
    min-width: 40px;
    width: 2.3vw;
  }
}

.tagsParagraph{
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #181202;

}

.tagInput {
  width: 100% !important;
  padding: 0 18px 0 18px;
  box-sizing: border-box;
  z-index: 1 !important;
  font-size: 18px;
  color: #000000 !important;
  height: 6vh !important;
  background-color: #FFFFFF;
  border: 1px solid #D1D2D5;
  border-radius: 12px;
}

.columnDiv{
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.btnDiv {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: #F5F7FF;
  border: 2px solid #D1D2D5;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cancelBtn{
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #092A64;
  background: none;
  border: none;

}

.submitBtnDiv{
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.saveAndAddBtn{
  cursor: pointer;
  width: 10vw;
  height: 5vh;
  min-width: 240px;
  min-height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  box-shadow: 4.04361px 4.04361px 10.109px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: #FFFFFF;
  background: #092A64;
  border-radius: 12px;
  border: none;

  margin-right: 30px;
}

.saveBtn {
  cursor: pointer;
  width: 10vw;
  height: 5vh;
  min-width: 160px;
  min-height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  box-shadow: 4.04361px 4.04361px 10.109px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #FFFFFF;
  background: #092A64;
  border-radius: 12px;
  border: none;

  margin-right: 30px;
}