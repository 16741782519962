.popupContainer {
  z-index: 2 !important;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.popupBody {
  z-index: 2 !important;
  position: absolute;
  left: 35%;
  right: 35%;
  bottom: 20%;
  top: 20%;
  min-height: 425px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5vh 3vw 2vh 3vw;
  border-radius: 15px;
  border-color: black;
  background-color: #ECF3FF;;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.btnDiv{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
};

.confirmBtn{
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #092A64;
  background: none;
  border: none;
};

.cancelBtn{
  cursor: pointer;
  width: 8vw;
  height: 5vh;
  min-width: 150px;
  min-height: 50px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  box-shadow: 4.04361px 4.04361px 10.109px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: #FFFFFF;
  background: #092A64;
  border-radius: 12px;
  border: none;
};