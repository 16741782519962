.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus{
  color: inherit !important;

  z-index: 1 !important;
  -webkit-text-fill-color: inherit !important;

  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.inputDiv {
  z-index: 1!important;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  background: #BED5FF;
  border: 1px solid rgba(41, 118, 255, 0.12);
  border-radius: 12px;
  box-sizing: border-box;
  padding:0 18px 0 18px;
  span{
    cursor: pointer;
  };

}

.input{
  z-index: 1 !important;
  position: relative;
  background: none;
  border: none;
  width: 100%;
  padding: 0;
  height: 100%;
  font-size: 18px;
  color: #FFFFFF;
  border-radius: 12px;
  margin-left: 5px;
}
