.signin_section{
    width: 100%;
    height: 100vh;
    gap: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.section_div{
    width: 40%;
}

.img_div{
    width: 50%;
    img{
        height: 70vh;
        width: 100%;
    }
}
