

.customBreadcrumbs{
  background: none;
  border-radius: 5px;
  padding:8px 5px 8px 12px;
  width: fit-content;
}

.link{
  
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #663B20;
  text-decoration: none;
}

.span{
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #663B20;
}