.main_section_div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_section{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    align-items: flex-start;
    gap: 20px;
}

.loginInput{
    width: 100%;
    min-height:60px;
    height: 7vh; 
    margin-top: 10px;
}

.inputdiv{
    width: 100%;
}

.label{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #376367;
}

.heading{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    /* identical to box height, or 34px */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #663B20;
}

.login_btn{
    cursor: pointer;
    width: 100%;
    border: none;
    height: 7vh;
    background: #663B20;
    border-radius: 12px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

}

.recover_password{
    cursor: pointer;
    background: none;
    border: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    letter-spacing: 0.02em;
    text-decoration-line: underline;
    text-transform: capitalize;

    color: #223D40;
}

.register_btn{
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #223D40;

    button{
        cursor: pointer;
        border: none;
        background: none;
        font-size: 18px;
        line-height: 120%;
        /* identical to box height, or 22px */

        letter-spacing: 0.02em;
        text-transform: capitalize;
        text-decoration-line: underline;

        color: #FFA70B;
    }
}