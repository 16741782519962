
.customInput:-webkit-autofill,
.customInput:-webkit-autofill:hover,
.customInput:-webkit-autofill:focus,
.email_input:-webkit-autofill,
.email_input:-webkit-autofill:hover,
.email_input:-webkit-autofill:focus{
  color: inherit !important;

  z-index: 1 !important;
  -webkit-text-fill-color: inherit !important;
  font-size: 18px;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.email_input{
  text-transform: lowercase;
  box-sizing: border-box;
  font-family: 'Lato';

  padding: 0 10px 0 10px;  font-size: 18px;
/* identical to box height */
  margin: 0;
  letter-spacing: 0.02em;
  font-size: 18px;
  background: #FFFFFF;
  border: 1.5px solid #663B20;
  border-radius: 12px;
  border: none;
}

.email_input:focus{
  outline: none !important;
}

.customInput::-webkit-outer-spin-button,
.customInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.customInput[type=number] {
  -moz-appearance: textfield;
}
.customInput {
  padding: 0 10px 0 10px;
  margin: 0;
  z-index: 1 !important;
  position: relative;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  font-family: 'Lato';
  font-size: 18px;
/* identical to box height */
  letter-spacing: 0.02em;
}

.customInputDiv{ 
    z-index: 1!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px 0 0;

    box-sizing: border-box;
   
    background: #FFFFFF;
    border: 1.5px solid #663B20;
    border-radius: 12px;
    span{
      cursor: pointer;
    };
}
.customInput:focus {
    outline: none !important;
  }
